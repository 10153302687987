import { render, staticRenderFns } from "./ChainBlock.vue?vue&type=template&id=4e8cf543&scoped=true&"
import script from "./ChainBlock.vue?vue&type=script&lang=ts&"
export * from "./ChainBlock.vue?vue&type=script&lang=ts&"
import style0 from "./ChainBlock.vue?vue&type=style&index=0&id=4e8cf543&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8cf543",
  null
  
)

export default component.exports